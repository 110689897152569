@import '../partials//colors';

.dropdown_structure {
  width: 160px;
  border: none;
  border-top: 2px solid map-get($theme-colors, 'secondary');
  height: 50px;
  border-radius: 0;
  cursor: pointer;
  box-shadow: none !important;
}

.dropdown_structure option {
  background: white !important;
  background-color: white !important;
  font-weight: 600;
  color: map-get($theme-colors, 'secondary');
}

.dropdown_structure:active,
.dropdown_structure:focus,
.dropdown_structure:hover {
  box-shadow: none !important;
  border-top: 2px solid map-get($theme-colors, 'secondary');
}

.kimp_image_size {
  width: 15rem;
}

.new_request_tabs {
  overflow: scroll;
  display: flex;
  align-items: center;
  overflow-y: hidden;

  button {
    border: 0px;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1.5rem;

    &.active {
      border-bottom: 4px solid map-get($theme-colors, 'primary');
      font-weight: 600;
    }
  }
}

.set_white_space {
  white-space: pre;
}

.check_size {
  font-size: 10px;
}

.down_arrow_icon {
  background-color: white;
}

.dimension_arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M6.30669 7.69401L0.287047 1.67316C0.103186 1.4893 -0.000105486 1.23993 -0.000105479 0.97991C-0.000105472 0.719893 0.103186 0.470524 0.287047 0.286664C0.470907 0.102804 0.720276 -0.000488275 0.980293 -0.000488281C1.24031 -0.000488288 1.48968 0.102804 1.67354 0.286664L6.99963 5.61336L12.3257 0.287875C12.5117 0.115244 12.7573 0.0213937 13.0109 0.0260425C13.2646 0.0306912 13.5066 0.133479 13.6861 0.312806C13.8656 0.492134 13.9686 0.734045 13.9735 0.987716C13.9783 1.24139 13.8847 1.48707 13.7122 1.67316L7.69258 7.6928C7.50879 7.87656 7.25953 7.97979 6.99963 7.97979C6.73974 7.97979 6.49048 7.87656 6.30669 7.6928V7.69401Z' fill='%23219186'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='14' height='7.98036' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 1.4rem center;
}

.no_left_border {
  border-left: none;
}

.no_right_border {
  border-right: none;
}

.green_arrow_size {
  width: 1.5rem;
}
.task_name_field:focus {
  outline: white !important;
  box-shadow: none !important;
}

.task_name_field:-webkit-autofill,
.task_name_field:-webkit-autofill:hover,
.task_name_field:-webkit-autofill:focus,
.task_name_field:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: map-get($theme-colors, 'primary') !important;
  caret-color: map-get($theme-colors, 'primary') !important;
}

.task_name_field::placeholder {
  color: map-get($theme-colors, 'secondary');
}

@media (min-width: 568px) {
  .dropdown_structure {
    border-top: none !important;
  }
}

@media (min-width: 768px) {
  .kimp_image_size {
    width: 20rem;
  }
  .force_pad {
    padding-top: 1rem;
    padding: 1rem 2rem;
  }
  .new_request_tabs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    button {
      border: 0px;
      background-color: transparent;
      display: inline-flex;
      align-items: center;
      padding: 0.5rem 1.5rem;

      &.active {
        border-bottom: 4px solid map-get($theme-colors, 'primary');
      }
    }
  }
}

@media (min-width: 992px) {
  .kimp_image_size {
    width: 22rem;
  }
  .dropdown_structure {
    width: 160px;
    border: none;
    height: 40px;
    border-radius: 0;
    border-left: 0.12rem solid lightgrey;
    cursor: pointer;
    box-shadow: none !important;
    border-top: none !important;
  }

  .dropdown_structure:active,
  .dropdown_structure:focus,
  .dropdown_structure:checked {
    border-left: 0.12rem solid lightgrey;
    box-shadow: none !important;
    border-top: none !important;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
}

@media (min-width: 1200px) {
  .custom_input_size,
  .button_height {
    height: 3rem !important;
    width: 30rem;
  }
  .kimp_image_size {
    width: 24rem;
  }
}

.custom_size {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  border: 0px;
  outline: 0;
  position: relative;
  svg {
    width: 160px;
    height: 154px;
  }
  input {
    height: 20px;
    max-width: 40px;
  }
  .img_check {
    position: absolute;
    z-index: 1;
    top: 0.5rem;
    right: 0.8rem;
    width: 16px;
    height: 16px;
  }
}
.pedefined_size {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  border: 0px;
  outline: 0;
  position: relative;
  svg {
    width: 160px;
    height: 154px;
  }
  .img_check {
    position: absolute;
    z-index: 1;
    top: 0.5rem;
    right: 0.8rem;
    width: 16px;
    height: 16px;
  }
}

.small_input {
  height: 20px;
  max-width: 50px;
}

.force_pad {
  padding-top: 1rem;
}
