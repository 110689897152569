@import '../partials/colors';

.history_tab {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  button {
    // border: 0.1rem solid map-get($theme-colors, 'secondary');
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    width: 150px;
    text-align: center;
    border-radius: 3px;

    &.active {
      border-bottom: 4px solid map-get($theme-colors, 'primary');
      color: map-get($theme-colors, 'primary');
      font-weight: 600;
      width: 150px;
      background-color: white;
    }
  }
  img {
    width: 0.8rem;
    display: none !important;
    &.show {
      display: block !important;
      width: 0.8rem;
    }
  }
}

.overflow_box {
  overflow: hidden;
}

.stat_success {
  color: #1aaf59;
}

.stat_missing {
  color: #faa215;
}

.stat_onHold {
  color: #ff6a1c;
}

.stat_issues {
  color: #ff4a4a;
}

.card_bg_grey {
  background-color: #f7f7f7 !important;
}

.delete_btn {
  background: #ff4a4a !important;
}

.delete_btn:hover,
.delete_btn:focus,
.delete_btn:active {
  background: #ff4a4a !important;
}

.revision_btn {
  background: #ffaf19 !important;
}

.revision_btn:hover,
.revision_btn:focus,
.revision_btn:active {
  background: #ffaf19 !important;
}

.time_box {
  background-color: #f7f7f7;
  border-radius: 4px;
}

.header_btn {
  background: white;
  border: 1px solid lightgray;
}
.header_btn:focus,
.header_btn:active,
.header_btn:hover {
  background: white !important;
  border: 1px solid lightgray;
}

.star_icon_size {
  width: 1rem;
}

.close_icon {
  top: 0;
  right: 0;
  padding: 0;
  background: white !important;
  box-shadow: none !important;
  border: none !important;
}

.close_icon:active,
.close_icon:focus,
.close_icon:hover {
  background: white !important;
  box-shadow: none !important;
  border: none !important;
}

.close_details_icon {
  top: 1.5rem;
  right: 1.5rem;
  padding: 0;
  background: #f7f7f7 !important;
  box-shadow: none !important;
  border: none !important;
}

.close_details_icon:active,
.close_details_icon:focus,
.close_details_icon:hover {
  background: #f7f7f7 !important;
  box-shadow: none !important;
  border: none !important;
}

.close_details_icon_size {
  width: 1.5rem;
}

.close_icon_size {
  width: 1.2rem;
}

.circle_badge {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border: 1px solid #e8e7e7;
  color: map-get($theme-colors, 'darker');
  margin: 0 0.1rem;
}

.input_box_structure {
  height: 3rem;
}

.chat_box_structure {
  min-height: 3rem;
}

.bot_icon_size {
  width: 2.5rem;
}

.final_star_icon_size {
  border: 0.1rem solid lightgrey;
  padding: 0.45rem !important;
}

.process_btn {
  background: #28a745;
  border: 1px solid #28a745 !important;
}

.process_btn:active,
.process_btn:focus,
.process_btn:hover {
  background: #28a745 !important;
  border: 1px solid #28a745 !important;
}

.btn_structure {
  min-width: 150px !important;
}

@media (min-width: 320px) {
  .chat_box_structure {
    max-width: 180px;
  }
}

@media (min-width: 375px) {
  .chat_box_structure {
    max-width: none;
  }
}

.request_section {
  height: 100% !important;
}

.card_body_request_history {
  height: calc(100vh - 500px);
  overflow-y: auto;
}
