.card_size {
  max-width: 500px !important;
  height: 300px;
  border-radius: 4px;
}

.background_with_banner {
  background-image: url('../../assets/images/celebration-banner.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.thankyou_with_banner {
  background-image: url('../../assets/images/thankyou-with-banner.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // width: 100%;
}

.star_icon_size {
  width: 3.8rem;
}
.close_icon_size {
  width: 1.5rem;
}
.hover_btn {
  top: 1rem;
  right: 1rem;
  background: white !important;
  box-shadow: none !important;
  border: none !important;
}

.hover_btn:focus,
.hover_btn:active,
.hover_btn:focus {
  top: 1rem;
  right: 1rem;
  background: white !important;
  box-shadow: none !important;
  border: none !important;
}

.custom_star_space {
  span {
    width: 3.8rem;
  }
}

.star_size {
  height: 35px;
  width: 35px;
}

@media (min-width: 375px) {
  .custom_star_space {
    span {
      width: 4rem;
    }
  }

  .star_size {
    height: 40px;
    width: 40px;
  }
}

@media (min-width: 576px) {
  .custom_star_space {
    span {
      width: 5rem;
    }
  }
  .star_size {
    height: 55px;
    width: 55px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
