.btn_collapse {
  background-color: transparent;
  border: 0px;
  padding: 0.75rem 0.75rem;
  display: block;
  color: black;
  width: 100%;
  text-align: left;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: black;
  }
  img {
    margin-right: 1rem;
  }
  svg {
    margin-left: 1rem;
    transition: transform 0.3s;
  }
  &.is-open {
    background-color: rgba(0, 0, 0, 0.1);
    svg {
      transform: rotate(-180deg);
    }
  }
}

.permission_list {
  ul {
    list-style-type: none;
    list-style-position: inside;
    padding: 0;
    li {
      border-bottom: 1px solid #bdbdbd;
      padding: 0.75rem 0.75rem;
      text-align: left;
      a {
        color: #fff;
        &:hover {
          color: white;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }

  input[type='checkbox'] {
    margin-top: 0.4rem !important;
  }
}
