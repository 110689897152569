.history_card {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  min-height: 30%;
}

.close_details_icon {
  top: 1.5rem;
  right: 1.5rem;
  padding: 0;
  background: #ffffff !important;
  box-shadow: none !important;
  border: none !important;
}

.close_details_icon:active,
.close_details_icon:focus,
.close_details_icon:hover {
  background: #ffffff !important;
  box-shadow: none !important;
  border: none !important;
}

.close_details_icon_size {
  width: 1.6rem;
}

.border_bottom {
  border-bottom: 1px solid lightgray;
}
