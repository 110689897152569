@import '../partials/colors';

.table-bordered {
  thead {
    th {
      background-color: #fff;
      overflow-x: hidden;
      border-left-width: 0px;
      border-right-width: 0px;
      &:first-child {
        border-left-width: 1px;
      }
      &:last-child {
        border-right-width: 1px;
      }
    }
  }
  tbody {
    box-shadow: inset 0px 10px 15px #ededed;
    td {
      vertical-align: middle;
      border-left-width: 0px;
      border-right-width: 0px;
      &:first-child {
        border-left-width: 1px;
      }
      &:last-child {
        border-right-width: 1px;
      }
    }
  }
}

.custom-checkbox.circle .custom-control-label::before {
  border-radius: 50%;
  cursor: pointer;
}

.dropdown-menu.show.role-menu {
  left: 75px !important;
}

.popover.custom-popover {
  z-index: 1020 !important;
}
.form-control.board-arrw,
.form-control.grn-arrw {
  appearance: none !important;
}

.form-control::placeholder {
  color: #bdbdbd;
}

.form-control.board-arrw:disabled {
  background-color: white;
}

.custom-control-label::after {
  cursor: pointer;
}

.circle.custom-control.custom-checkbox {
  input[type='checkbox']:disabled + label::before {
    background: white;
  }
}

.circle.greencircle.custom-control.custom-checkbox {
  input[type='checkbox']:disabled + label::before {
    background: map-get($theme-colors, 'primary');
  }
}

.visibility_label.custom-control.custom-radio {
  label {
    cursor: pointer;
  }
}

.card-img.user-avatar-icon {
  border-radius: 50px;
  width: 2.2rem;
}

.form-control.is-invalid.remove-focus:active,
.form-control.is-invalid.remove-focus:focus {
  border-radius: 0;
  box-shadow: none;
}

.remember-me.custom-control.custom-checkbox {
  > label::before {
    margin-top: 0.5px; //changing position of checkbox
  }
}

.invalid-feedback.no-margin-required {
  p {
    margin-bottom: 0;
    padding-top: 0.5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .order-box .form-control {
    height: auto !important;
    padding: 0 !important;
  }
}
