.custom_text_size_09 {
  font-size: 0.9rem;
}

.unsorted_dropdown_toggle {
  top: 0;
  right: 0;
  min-width: 110px;
  color: grey !important;
  background-color: white;
  border-radius: 0rem;
  border-color: rgba(0, 0, 0, 0.125) !important;
  background: white !important;
  box-shadow: none;
  border-radius: 0.25rem;
  &::after {
    display: none;
  }
}
.unsorted_dropdown_toggle:active,
.unsorted_dropdown_toggle:hover,
.unsorted_dropdown_toggle:focus {
  background-color: white;
  background: white !important;
  color: grey !important;
  border-color: rgba(0, 0, 0, 0.125) !important;
  box-shadow: none !important;
}

.unsorted_item:hover {
  background-color: #219186;
  color: white !important;
}
.unsorted_menu {
  min-width: 150px !important;
}
