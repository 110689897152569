@media (min-width: 320px) {
  .popover_collection_card {
    width: 70%;
  }
}

@media (min-width: 360px) {
  .popover_collection_card {
    width: 60%;
  }
}

@media (min-width: 375px) {
  .popover_collection_card {
    width: 60%;
  }
}

@media (min-width: 411px) {
  .popover_collection_card {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .popover_collection_card {
    width: 100%;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
