.invite_dropdown_toggle {
  top: 0;
  right: 0;
  min-width: 110px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: grey !important;
  background-color: white;
  border-radius: 0rem;
  border-color: #ced4da !important;
  background: white !important;
  box-shadow: none;
  border-radius: 0.25rem;
  &::after {
    display: none;
  }
}
.invite_dropdown_toggle:active,
.invite_dropdown_toggle:hover,
.invite_dropdown_toggle:focus {
  background-color: white;
  background: white !important;
  color: grey !important;
  border-color: #ced4da !important;
  //   box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.border_all {
  border: 1px solid #ced4da !important;
}

.member_popover_structure {
  width: 250px;
}

.feeds_popover_structure {
  width: 200px;
}

.clients_popover_structure {
  width: 150px;
}
