$box-shadow: 0px 10px 15px #ededed;

@import './partials/colors';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/react-toastify/dist/ReactToastify.min.css';
@import './partials/mixins';
@import './partials/custom';
@import './partials/overrides';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: #f0f0f0;
  color: map-get($theme-colors, 'darker');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main.dashboard {
  width: 100%;
  position: relative;
  > .container-fluid {
    padding-left: 15px;
    padding-top: calc(75px + 15px);
    > .card {
      min-height: calc(100vh - 75px - 30px - 56px);
      .card-header {
        background-color: #fff;
        text-align: center;
        .card-subtitle {
          color: #969696;
        }
      }
    }
  }
}

a {
  color: map-get($map: $theme-colors, $key: 'dark');
}

@media (min-width: 768px) {
  main.dashboard {
    > .container-fluid {
      padding-left: 285px;
    }
  }
  html {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  main.dashboard {
    > .container-fluid {
      padding-left: 285px;
    }
  }
}

*::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #1b6d64;
  outline: 1px solid slategrey;
}

.text-font {
  font-family: 'Monserrat';
}

@font-face {
  font-family: 'Monserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Monserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Monserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Monserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

.error-text {
  color: red;
}

.btn-back-white,
.btn-back-white:focus,
.btn-back-white:active {
  background: white;
  box-shadow: none;
}

.disable-custom-button,
.disable-custom-button:focus,
.disable-custom-button:active {
  cursor: default !important;
  opacity: 0.65;
  border-color: #219186;
  background-color: transparent;
}

.disable-custom-button:hover {
  cursor: default !important;
  opacity: 0.65;
  border-color: #969696;
  background-color: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-picker-dropdown {
  z-index: 1061 !important;
}

.ven-due-date-popup {
  padding: 10px;
  position: absolute;
  z-index: 1050;
  margin-top: 10px;
  width: 210px;

  .ven-due-date-time-picker {
    width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.display-image {
  .close {
    position: absolute;
    top: 0px;
    left: 100%;
    color: #ffffff;
    font-size: 2.5rem;
  }
}

.asset-selected {
  border-style: solid;
  border-width: 5px;
  border-radius: 5px;
  border-color: transparent;
  &::before {
    content: url('../assets/icons/check.svg');
    height: 33px;
    width: 33.5px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1070;
    background: white;
    border-radius: 50%;
  }
}

.asset-unselected {
  border-style: solid;
  border-width: 5px;
  border-radius: 5px;
  border-color: transparent;
}

.h-100 {
  height: 100%;
}

.single_line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.search_popover {
  max-height: 80%;
  max-width: none;
  width: 600px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  .search-task-title {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .search-task-project {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    color: map-get($theme-colors, 'secondary') !important;
  }
}

.search-input {
  padding: 0;
  width: 0;
  opacity: 0 !important;
  transition: width 0.3s;
  &.show {
    padding: 0.375rem 0.75rem;
    opacity: 1 !important;
    width: 600px !important;
  }
}

.kimp-search-input {
  input::placeholder {
    color: #495057;
  }
}

.invitation-role {
  &.form-control {
    &.is-invalid {
      border-color: #fe0a13;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url('../assets/icons/error-icon.svg');
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 1.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
}

.details-2-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
