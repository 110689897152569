@import '../partials/colors';

.payment_cards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.default_payment_card {
  border-bottom: 6px solid map-get($theme-colors, 'primary');
  white-space: nowrap;
}
.payment_card {
  white-space: nowrap;
  position: relative;
}
.add_card {
  min-height: 164px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.save_button {
  width: 200px;
}

@media (min-width: 768px) {
  .payment_cards {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .default_payment_card {
    width: 280px;
    margin-right: 1rem;
  }
  .payment_card {
    width: 280px;
    margin-right: 1rem;
  }
  .add_card {
    width: 280px;
    > div {
      width: 280px;
    }
  }
}

.card_dropdown_toggle {
  position: absolute;
  top: -1.25rem;
  right: -1.25rem;
  color: white;
  padding: 0.2rem 1rem;
  opacity: 0.7;
  border-radius: 0rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  &::after {
    display: none;
  }
}
