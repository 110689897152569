.text-size-xxs {
  font-size: 0.8rem !important;
}

.text-size-xs {
  font-size: 1rem;
}
.text-size-sm {
  font-size: 1.2rem;
}
.text-size-md {
  font-size: 1.4rem;
}
.text-size-lg {
  font-size: 1.7rem;
}
.text-size-xl {
  font-size: 2rem;
}

.text-size-semi-md {
  font-size: 1.6rem;
}

.text-weight-bold {
  font-weight: 600;
}

.shadow-on-box {
  box-shadow: 0.1rem 0.1rem 0.3rem #aaaaaa;
}

.text-color-secondary {
  color: #f0f0f0;
}

.no-wrap {
  white-space: nowrap !important;
}

.white-space-pre {
  white-space: pre !important;
}

.custom-white-space {
  overflow-wrap: break-word;
}

.no-border {
  border: none !important;
}

.text-underline,
.text-underline:hover {
  text-decoration: underline;
}

.no-border-right {
  border-right: none !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}
.underline-text {
  text-decoration: underline;
}

.mouse-hover {
  cursor: pointer;
}

.switch {
  background-color: transparent;
  border: 2px solid map-get($theme-colors, 'secondary');
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  align-items: center;
  &.is-checked {
    flex-direction: row-reverse;
    > div {
      margin-right: 0rem;
      margin-left: 0.5rem;
      color: #fff;
      background-color: map-get($theme-colors, 'primary');
    }
  }
  p {
    font-size: 1rem;
  }
  > div {
    background-color: #e0e0e0;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
    display: inline-flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e0e0e0;
    margin-right: 0.5rem;
    transition: all 0.3s;
  }
}

.switch-labelless {
  background-color: transparent;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  align-items: center;
  &.is-checked {
    flex-direction: row-reverse;
    > div {
      margin-right: 0rem;
      margin-left: 0.5rem;
      color: #fff;
      background-color: map-get($theme-colors, 'primary');
    }
  }
  p {
    font-size: 1rem;
  }
  > div {
    background-color: #e0e0e0;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
    display: inline-flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e0e0e0;
    margin-right: 0.5rem;
    transition: all 0.3s;
  }
}

.breadcrumb {
  background-color: white !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: url('../../assets/icons/chevron-right-icon.svg');
}

.custom-control-input-green:focus ~ .custom-control-label::before {
  border-color: #28a745 !important;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
}

.h-vh-100 {
  min-height: 100vh;
}

.w-vw-80 {
  max-height: 80vh;
}

.bg-transparent {
  background-color: transparent;
}

.z-1070 {
  z-index: 1070 !important;
}

.custom-overflow-box {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-hide-caret::after {
  display: none;
}

.form-control-country-code {
  width: 70px !important;
  flex: none !important;
}
