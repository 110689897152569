@import '../partials/colors';

.active_row:hover {
  background: map-get($theme-colors, 'primary');

  svg > path {
    fill: white !important;
  }
}

.active_text:hover {
  color: white;
}
.pen_icon {
  fill: #969696;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .popover_collection_card {
    width: 70%;
  }
  /* STYLES GO HERE */
  .side_section {
    display: flex;
  }

  .boards_sidebar {
    h5 {
      padding: 2px;
    }
  }

  .section_heading {
    color: #969696;
  }

  .search_box {
    margin-top: 30px;
  }
  .section_heading {
    h5 {
      font-size: 9px;
    }
  }
  .section_heading_button {
    color: #969696;
    margin-top: 4px;
    background-color: white !important;
    border: none;
    text-decoration: underline;
    text-align: center;
  }

  .section_heading_spacing {
    color: #969696;
    padding-bottom: 10px;
  }

  .section_heading_bottom {
    font-size: 10px;
    padding: 0 !important;
    margin: 0 !important;
  }

  .vl {
    border-left: 2px solid #ebeaea;
    height: 100vh;
    display: flex;
    opacity: 0.7;
  }

  .border_radius_primary {
    border-radius: 0.1rem !important;
  }

  .create_collection {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .row_end {
    padding-left: 88%;
    margin-bottom: 0;
  }

  .close_button {
    width: 5px;
  }

  .card_text_color {
    color: gray;
  }
  .card_shadow {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  }
  .card_heading {
    font-size: 8px;
    font-weight: 700;
    text-align: center;
  }
  .name_list {
    display: grid;
  }

  .card_name {
    color: #969696;
  }

  .card_user {
    display: inline-flex;
    padding: 0;
  }

  .card_user_sign {
    font-size: 10px;
    font-weight: 700;
    background-color: white;
    border: white;
  }

  .modal_element {
    width: 70%; /* Occupy the 90% of the screen width */
    max-width: 75%;
    height: 20vh;
  }

  .modal_header {
    border: none !important;
    border: white;
  }

  .sidesection_icon {
    background-color: white;
    border: white;
    color: #969696;
    margin-top: 3px;
    text-align: start;
    box-shadow: none;
    font-size: 10px;
    font-weight: 500;
  }

  .sidesection_icon_img {
    width: 8px;
    margin-bottom: 15px;
  }
  .sidesection_icon_img_gray {
    width: 8px;
    fill: #969696;
    background-color: white;
    margin-bottom: 2px;
  }
  .cross_img {
    width: 12px;
  }

  .sidesection_icon:hover,
  .sidesection_icon:focus,
  .sidesection_icon:active {
    background-color: white !important;
    border: white;
    color: #969696 !important;
    margin-top: 3px;
    outline: 0;
    box-shadow: none !important;
  }

  .boards_popover_big {
    width: 100%;
  }

  .boards_popover_big_edit {
    padding: 5px;
    width: 70%;
  }

  .popover_big {
    width: 55%;
  }

  .boards_popover_small {
    padding: 5px;
    width: 55%;
    margin: 10px;
  }
  .line_spacing {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
    padding: 4px;
  }

  .line_spacing:active,
  .line_spacing:focus {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
    color: white;
    img {
      color: white;
    }
  }

  .line_spacing_tick {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0px;
  }

  .line_spacing_tick:active,
  .line_spacing_tick:focus {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    background-color: #219186;
    color: white;
  }

  .line_spacing_name {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    label {
      align-content: center;
      font-size: 11px;
    }
  }

  .list_name {
    align-items: center;
    align-self: center;
  }

  .line_spacing_name:active,
  .line_spacing_name:focus {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
  }

  .line_spacing_pencil {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
  }
  .line_spacing_pencil:active,
  .line_spacing_pencil:focus {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    background-color: #219186;
    color: white;
  }

  .button_nobg {
    background-color: white;
    border: none;
    margin-top: 10px;
  }

  .button_cross {
    width: auto;
    background-color: white;
    border: none;
  }

  .buttons {
    display: flex;
    padding: 0;
  }

  .buttons_div_long {
    width: 70%;
  }

  .buttons_div {
    width: 25%;
  }

  .danger_button {
    background-color: #fe0a13;
    margin: 5px;
  }

  .danger_button:active,
  .danger_button:hover,
  .danger_button:focus {
    background-color: #cf1218;
    margin: 5px;
  }

  .normal_button {
    margin: 5px;
  }

  .normal_button:active,
  .normal_button:hover,
  .normal_button:focus {
    margin: 5px;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  .popover_collection_card {
    width: 70%;
  }
  /* STYLES GO HERE */
  /* STYLES GO HERE */
  .side_section {
    display: flex;
  }

  .boards_sidebar {
    h5 {
      padding: 2px;
    }
  }

  .section_heading {
    color: #969696;
  }

  .search_box {
    margin-top: 29px;
  }
  .section_heading {
    h5 {
      font-size: 9px;
    }
  }
  .section_heading_button {
    color: #969696;
    margin-top: 4px;
    background-color: white !important;
    border: none;
    text-decoration: underline;
    text-align: center;
  }

  .section_heading_bottom {
    font-size: 10px;
    padding: 0 !important;
    margin: 0 !important;
  }

  .section_heading_spacing {
    color: #969696;
    padding-bottom: 10px;
  }

  .vl {
    border-left: 2px solid #ebeaea;
    height: 100vh;
    display: flex;
    opacity: 0.7;
    margin-top: 29px;
  }

  .border_radius_primary {
    border-radius: 0.1rem !important;
  }

  .create_collection {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .row_end {
    padding-left: 88%;
    margin-bottom: 0;
  }

  .close_button {
    width: 5px;
  }

  .card_text_color {
    color: gray;
  }
  .card_shadow {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  }
  .card_heading {
    font-size: 8px;
    font-weight: 700;
    text-align: center;
  }
  .name_list {
    display: grid;
  }

  .card_name {
    color: #969696;
  }

  .card_user {
    display: inline-flex;
    padding: 0;
  }

  .card_user_sign {
    font-size: 10px;
    font-weight: 700;
    background-color: white;
    border: white;
  }

  .modal_element {
    width: 70%; /* Occupy the 90% of the screen width */
    max-width: 75%;
    height: 22vh;
  }

  .modal_header {
    border: none !important;
    border: white;
  }

  .sidesection_icon {
    background-color: white;
    border: white;
    color: #969696;
    margin-top: 3px;
    text-align: start;
    box-shadow: none;
    font-size: 10px;
    font-weight: 500;
  }

  .sidesection_icon_tick {
    padding-top: 4px;
  }

  .sidesection_icon_img {
    width: 8px;
  }
  .sidesection_icon_img_gray {
    width: 8px;
    fill: #969696;
    background-color: white;
    margin-bottom: 2px;
  }
  .cross_img {
    width: 13px;
  }

  .sidesection_icon:hover,
  .sidesection_icon:focus,
  .sidesection_icon:active {
    background-color: white !important;
    border: white;
    color: #969696 !important;
    margin-top: 3px;
    outline: 0;
    box-shadow: none !important;
  }

  .boards_popover_big {
    padding: 5px;
    width: 48%;
    margin: 5px;
  }

  .boards_popover_big_edit {
    padding: 5px;
    width: 70%;
  }

  .popover_big {
    width: 62%;
    padding: 3px;
  }

  .boards_popover_small {
    padding: 5px;
    width: 55%;
    margin: 10px;
  }

  .line_spacing {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
  }

  .line_spacing:active,
  .line_spacing:focus {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
    color: white;
    img {
      color: white;
    }
  }

  .line_spacing_tick {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 5%;
  }

  .line_spacing_tick:active,
  .line_spacing_tick:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 5%;
    background-color: #219186;
    color: white;
  }

  .line_spacing_name {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2px;
    label {
      align-content: center;
      font-size: 9px;
    }
  }
  .line_spacing_name:active,
  .line_spacing_name:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
    color: white;
  }

  .line_spacing_pencil {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 10%;
  }
  .line_spacing_pencil:active,
  .line_spacing_pencil:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 10%;
    background-color: #219186;
    color: white;
  }

  .button_nobg {
    background-color: white;
    border: none;
    margin-top: 10px;
  }

  .button_cross {
    width: auto;
    background-color: white;
    border: none;
  }

  .buttons {
    display: flex;
    padding: 0;
  }

  .buttons_div_long {
    width: 70%;
  }

  .buttons_div {
    width: 25%;
  }

  .danger_button {
    background-color: #fe0a13;
    margin: 5px;
  }

  .danger_button:active,
  .danger_button:hover,
  .danger_button:focus {
    background-color: #cf1218;
    margin: 5px;
  }

  .normal_button {
    margin: 5px;
  }

  .normal_button:active,
  .normal_button:hover,
  .normal_button:focus {
    margin: 5px;
  }
}
@media (min-width: 375px) and (max-width: 812px) {
  .popover_collection_card {
    width: 70%;
  }
  .side_section {
    display: flex;
  }

  .boards_sidebar {
    h5 {
      padding: 1px;
      font-size: 10px;
    }
  }

  .section_heading {
    color: #969696;
  }

  .search_box {
    margin-top: 29px;
  }
  .section_heading {
    h5 {
      font-size: 7px;
    }
    display: inline-block;
  }
  .section_heading_button {
    color: #969696;
    margin-top: 4px;
    background-color: white !important;
    border: none;
    text-decoration: underline;
    text-align: center;
  }

  .section_heading_bottom {
    font-size: 10px;
    padding: 0 !important;
    margin: 0 !important;
  }

  .section_heading_spacing {
    color: #969696;
    padding-bottom: 10px;
  }

  .vl {
    border-left: 2px solid #ebeaea;
    height: 100vh;
    display: flex;
    opacity: 0.7;
    margin-top: 29px;
  }

  .border_radius_primary {
    border-radius: 0.1rem !important;
  }

  .create_collection {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .row_end {
    padding-left: 88%;
    margin-bottom: 0;
  }

  .close_button {
    width: 5px;
  }

  .card_text_color {
    color: gray;
  }
  .card_shadow {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  }
  .card_heading {
    font-size: 8px;
    font-weight: 700;
    text-align: center;
  }
  .name_list {
    display: grid;
  }

  .card_name {
    color: #969696;
  }

  .card_user {
    display: inline-flex;
    padding: 0;
  }

  .card_user_sign {
    font-size: 10px;
    font-weight: 700;
    background-color: white;
    border: white;
  }

  .modal_element {
    width: 70%; /* Occupy the 90% of the screen width */
    max-width: 75%;
    height: 16vh;
  }

  .modal_header {
    border: none !important;
    border: white;
  }

  .sidesection_icon {
    background-color: white;
    border: white;
    color: #969696;
    margin-top: 3px;
    text-align: start;
    box-shadow: none;
    font-size: 10px;
    font-weight: 500;
  }

  .sidesection_icon_tick {
    padding-top: 5px;
  }

  .sidesection_icon_pencil {
    margin-right: 2px;
  }

  .sidesection_icon_img {
    width: 8px;
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .sidesection_icon_img_gray {
    width: 8px;
    fill: #969696;
    background-color: white;
    margin-bottom: 1px;
  }
  .cross_img {
    width: 15px;
  }

  .sidesection_icon:hover,
  .sidesection_icon:focus,
  .sidesection_icon:active {
    background-color: white !important;
    border: white;
    color: #969696 !important;
    margin-top: 3px;
    outline: 0;
    box-shadow: none !important;
  }

  .boards_popover_big {
    padding: 5px;
    width: 48%;
    margin: 5px;
  }

  .boards_popover_big_edit {
    padding: 5px;
    width: 70%;
  }

  .popover_big {
    width: 60%;
    padding: 3px;
  }

  .boards_popover_small {
    padding: 5px;
    width: 55%;
    margin: 10px;
  }

  .line_spacing {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
  }

  .line_spacing:active,
  .line_spacing:focus {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
    color: white;
    img {
      color: white;
    }
  }

  .line_spacing_tick {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 5%;
  }

  .line_spacing_tick:active,
  .line_spacing_tick:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 5%;
    background-color: #219186;
    color: white;
  }

  .line_spacing_name {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 3px;
    label {
      align-content: center;
      font-size: 9px;
    }
  }
  .line_spacing_name:active,
  .line_spacing_name:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
    color: white;
  }

  .line_spacing_pencil {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 10%;
    padding-right: 4px;
  }
  .line_spacing_pencil:active,
  .line_spacing_pencil:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 10%;
    background-color: #219186;
    color: white;
  }

  .button_nobg {
    background-color: white;
    border: none;
    margin-top: 10px;
  }

  .button_cross {
    width: auto;
    background-color: white;
    border: none;
  }

  .buttons {
    display: flex;
    padding: 0;
  }

  .buttons_div_long {
    width: 70%;
  }

  .buttons_div {
    width: 25%;
  }

  .danger_button {
    background-color: #fe0a13;
    margin: 5px;
  }

  .danger_button:active,
  .danger_button:hover,
  .danger_button:focus {
    background-color: #cf1218;
    margin: 5px;
  }

  .normal_button {
    margin: 5px;
  }

  .normal_button:active,
  .normal_button:hover,
  .normal_button:focus {
    margin: 5px;
  }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* STYLES GO HERE */
  /* STYLES GO HERE */
  .side_section {
    display: flex;
  }

  .boards_sidebar {
    h5 {
      padding: 2px;
    }
  }

  .section_heading {
    color: #969696;
  }

  .search_box {
    margin-top: 20px;
  }
  .section_heading {
    h5 {
      font-size: 10px;
    }
  }
  .section_heading_button {
    color: #969696;
    margin-top: 4px;
    background-color: white !important;
    border: none;
    text-decoration: underline;
    text-align: center;
  }

  .section_heading_spacing {
    color: #969696;
    padding-bottom: 10px;
  }

  .vl {
    border-left: 2px solid #ebeaea;
    height: 100vh;
    display: flex;
    opacity: 0.7;
  }

  .border_radius_primary {
    border-radius: 0.1rem !important;
  }

  .create_collection {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .row_end {
    padding-left: 88%;
    margin-bottom: 0;
  }

  .close_button {
    width: 5px;
  }

  .card_text_color {
    color: gray;
  }
  .card_shadow {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  }
  .card_heading {
    font-size: 8px;
    font-weight: 700;
    text-align: center;
  }
  .name_list {
    display: grid;
  }

  .card_name {
    color: #969696;
  }

  .card_user {
    display: inline-flex;
    padding: 0;
  }

  .card_user_sign {
    font-size: 10px;
    font-weight: 700;
    background-color: white;
    border: white;
  }

  .modal_element {
    width: 50%; /* Occupy the 90% of the screen width */
    max-width: 55%;
    height: 22vh;
  }

  .modal_header {
    border: none !important;
    border: white;
  }

  .sidesection_icon {
    background-color: white;
    border: white;
    color: #969696;
    margin-top: 3px;
    text-align: start;
    box-shadow: none;
    font-size: 10px;
    font-weight: 500;
  }

  .sidesection_icon_img {
    width: 10px;
  }
  .sidesection_icon_img_gray {
    width: 10px;
    fill: #969696;
    background-color: white;
  }

  .cross_img {
    width: 16px;
  }

  .sidesection_icon:hover,
  .sidesection_icon:focus,
  .sidesection_icon:active {
    background-color: white !important;
    border: white;
    color: #969696 !important;
    margin-top: 3px;
    outline: 0;
    box-shadow: none !important;
  }

  .boards_popover_big {
    padding: 5px;
    width: 35%;
    margin: 5px;
  }

  .popover_big {
    width: 100%;
  }

  .boards_popover_big_edit {
    padding: 5px;
    width: 50%;
  }

  .boards_popover_small {
    padding: 5px;
    width: 50%;
    margin: 10px;
  }

  .line_spacing {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
  }

  .line_spacing:active,
  .line_spacing:focus {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 120px;
    background-color: #219186;
    color: white;
    img {
      color: white;
    }
  }

  .line_spacing_tick {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 5%;
  }

  .line_spacing_tick:active,
  .line_spacing_tick:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 5%;
    background-color: #219186;
    color: white;
  }

  .line_spacing_name {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    label {
      align-content: center;
      font-size: 10px;
    }
  }
  .line_spacing_name:active,
  .line_spacing_name:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
    color: white;
  }

  .line_spacing_pencil {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 10%;
  }
  .line_spacing_pencil:active,
  .line_spacing_pencil:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 10%;
    background-color: #219186;
    color: white;
  }

  .button_nobg {
    background-color: white;
    border: none;
    margin-top: 10px;
  }

  .button_cross {
    width: auto;
    background-color: white;
    border: none;
  }

  .buttons {
    display: flex;
    padding: 0;
  }

  .buttons_div_long {
    width: 70%;
  }

  .buttons_div {
    width: 25%;
  }

  .danger_button {
    background-color: #fe0a13;
    margin: 5px;
  }

  .danger_button:active,
  .danger_button:hover,
  .danger_button:focus {
    background-color: #cf1218;
    margin: 5px;
  }

  .normal_button {
    margin: 5px;
  }

  .normal_button:active,
  .normal_button:hover,
  .normal_button:focus {
    margin: 5px;
  }
}

/* iPad Pro and normal screen laptops ----------- */
@media only screen and (min-width: 1024px) {
  /* STYLES GO HERE */
  .side_section {
    display: flex;
  }

  .boards_sidebar {
    h5 {
      padding: 1px;
    }
  }
  .section_heading {
    h5 {
      font-size: 10px;
    }
  }
  .section_heading {
    color: #969696;
  }
  .section_heading_bottom {
    color: #969696;
    padding: 0;
    margin-right: 10px;
  }

  .section_heading_button {
    color: #969696;
    margin-top: 4px;
    background-color: white !important;
    border: none;
    text-decoration: underline;
    text-align: center;
  }

  .section_heading_spacing {
    color: #969696;
    padding-bottom: 5px;
  }

  .vl {
    border-left: 2px solid #ebeaea;
    height: 100vh;
    display: flex;
    opacity: 0.7;
  }

  .border_radius_primary {
    border-radius: 0.1rem !important;
  }

  .create_collection {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .row_end {
    padding-left: 88%;
    margin-bottom: 0;
  }

  .close_button {
    width: 5px;
  }

  .card_text_color {
    color: gray;
  }
  .card_shadow {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  }
  .card_heading {
    font-size: 8px;
    font-weight: 700;
    text-align: center;
  }
  .name_list {
    display: grid;
  }

  .card_name {
    color: #969696;
  }

  .card_user {
    display: inline-flex;
    padding: 0;
  }

  .card_user_sign {
    font-size: 10px;
    font-weight: 700;
    background-color: white;
    border: white;
  }
  .modal_div {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .modal_element {
    width: 50%; /* Occupy the 90% of the screen width */
    max-width: 55%;
    height: 17vh;
    margin-bottom: 50vh;
    margin-left: 30%;
  }

  .modal_header {
    border: none !important;
    border: white;
  }

  .sidesection_icon {
    background-color: white;
    border: white;
    color: #969696;
    margin-top: 3px;
    text-align: start;
    box-shadow: none;
    font-size: 10px;
    font-weight: 500;
  }
  .section_heading {
    h5 {
      font-size: 11px;
    }
  }
  .sidesection_icon_img {
    width: 11px;
  }
  .sidesection_icon_img_gray {
    width: 11px;
    fill: #969696;
    background-color: white;
  }
  .cross_img {
    width: 16px;
  }

  .sidesection_icon:hover,
  .sidesection_icon:focus,
  .sidesection_icon:active {
    background-color: white !important;
    border: white;
    color: #969696 !important;
    margin-top: 3px;
    outline: 0;
    box-shadow: none !important;
  }

  .boards_popover_big {
    padding: 5px;
    width: 32%;
  }

  .popover_big {
    width: 100%;
  }

  .boards_popover_big_edit {
    padding: 5px;
    width: 50%;
  }

  .boards_popover_small {
    padding: 5px;
    width: 50%;
    margin: 10px;
  }

  .line_spacing {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
  }

  .line_spacing:active,
  .line_spacing:focus {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    background-color: #219186;
    color: white;
    width: 100%;
    img {
      color: white;
    }
  }

  .line_spacing_tick {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 5%;
  }

  .line_spacing_tick:active,
  .line_spacing_tick:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 5%;
    background-color: #219186;
    color: white;
  }

  .line_spacing_name {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    label {
      align-content: center;
      font-size: 10px;
    }
  }
  .line_spacing_name:active,
  .line_spacing_name:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
    color: white;
  }

  .line_spacing_pencil {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 10%;
  }
  .line_spacing_pencil:active,
  .line_spacing_pencil:focus {
    line-height: 45%;
    display: flex;
    flex-wrap: wrap;
    width: 10%;
    background-color: #219186;
    color: white;
  }

  .button_nobg {
    background-color: white;
    border: none;
    margin-top: 10px;
  }

  .button_cross {
    width: auto;
    background-color: white;
    border: none;
  }

  .buttons {
    display: flex;
    padding: 0;
  }

  .buttons_div_long {
    width: 70%;
  }

  .buttons_div {
    width: 25%;
  }

  .danger_button {
    background-color: #fe0a13;
    margin: 5px;
  }

  .danger_button:active,
  .danger_button:hover,
  .danger_button:focus {
    background-color: #cf1218;
    margin: 5px;
  }

  .normal_button {
    margin: 5px;
  }

  .normal_button:active,
  .normal_button:hover,
  .normal_button:focus {
    margin: 5px;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1280px) {
  /* STYLES GO HERE */
  .side_section {
    display: flex;
    padding-top: 0;
  }

  .boards_sidebar {
    h5 {
      padding: 4px;
    }
  }

  .search_box {
    margin-top: 34px;
  }

  .section_heading {
    color: #969696;
  }

  .section_heading_button {
    color: #969696;
    margin-top: 10px;
    background-color: white !important;
    border: none;
    text-decoration: underline;
    text-align: center;
  }

  .section_heading_spacing {
    color: #969696;
    padding-bottom: 20px;
  }

  .vl {
    border-left: 2px solid #ebeaea;
    height: 550px;
    display: flex;
    opacity: 0.7;
    margin-top: 34px;
  }

  .border_radius_primary {
    border-radius: 0.1rem !important;
  }

  .create_collection {
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .row_end {
    padding-left: 92%;
    margin-bottom: 0;
  }

  .close_button {
    width: 10px;
  }

  .card_text_color {
    color: gray;
  }
  .card_shadow {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .card_heading {
    font-size: 11px;
    font-weight: 700;
    text-align: center;
  }
  .name_list {
    display: grid;
  }

  .card_name {
    color: #969696;
  }

  .card_user {
    display: inline-flex;
    padding: 0;
  }

  .card_user_sign {
    font-size: 15px;
    font-weight: 700;
    background-color: white;
    border: white;
  }

  .modal_element {
    width: 60%; /* Occupy the 90% of the screen width */
    max-width: 60%;
    height: 20vh;
    margin-top: 30vh;
  }

  .modal_header {
    border: none !important;
    border: white;
  }

  .sidesection_icon {
    background-color: white;
    border: white;
    color: #969696;
    margin-top: 3px;
    text-align: start;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
  }

  .sidesection_icon_tick {
    padding-top: 7px;
  }
  .section_heading {
    h5 {
      font-size: 12px;
    }
  }
  .sidesection_icon_img {
    width: 12px;
  }
  .sidesection_icon_img_gray {
    width: 12px;
    fill: #969696;
    background-color: white;
    margin-bottom: 1px;
  }
  .cross_img {
    width: 16px;
  }
  .section_heading_bottom {
    color: #969696;
    padding: 0;
  }
  .sidesection_icon:hover,
  .sidesection_icon:focus,
  .sidesection_icon:active {
    background-color: white !important;
    border: white;
    color: #969696 !important;
    margin-top: 3px;
    outline: 0;
    box-shadow: none !important;
  }

  .boards_popover_big {
    padding: 5px;
  }

  .boards_popover_big_edit {
    padding: 10px;
    width: 20%;
  }
  .popover_big {
    width: 100%;
  }

  .boards_popover_small {
    padding: 10px;
    width: 20%;
  }

  .line_spacing {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
    padding: 4px;
    * {
      font-size: 13px;
    }
  }

  .line_spacing:active,
  .line_spacing:focus {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    background-color: #219186;
    width: 100%;
    padding: 4px;
    color: white;
    img {
      color: white;
    }
  }

  .line_spacing_tick {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 0px;
    margin: 0px;
  }

  .line_spacing_tick:active,
  .line_spacing_tick:focus {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    background-color: #219186;
    color: white;
  }

  .line_spacing_name {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    label {
      align-content: center;
      font-size: 11px;
    }
  }

  .list_name {
    align-items: center;
    align-self: center;
    padding-top: 5px;
  }

  .line_spacing_name:active,
  .line_spacing_name:focus {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
  }

  .line_spacing_pencil {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
  }
  .line_spacing_pencil:active,
  .line_spacing_pencil:focus {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    background-color: #219186;
    color: white;
  }

  .button_nobg {
    background-color: white;
    border: none;
    margin-top: 10px;
  }

  .button_cross {
    width: auto;
    background-color: white;
    border: none;
  }

  .buttons {
    display: flex;
    padding: 0;
  }

  .buttons_div_long {
    width: 70%;
  }

  .buttons_div {
    width: 25%;
  }

  .danger_button {
    background-color: #fe0a13;
    margin: 10px;
  }

  .danger_button:active,
  .danger_button:hover,
  .danger_button:focus {
    background-color: #cf1218;
    margin: 10px;
  }

  .normal_button {
    margin: 10px;
  }

  .normal_button:active,
  .normal_button:hover,
  .normal_button:focus {
    margin: 10px;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1440px) {
  /* STYLES GO HERE */
  .side_section {
    display: flex;
    padding-top: 0;
  }

  .boards_sidebar {
    h5 {
      padding: 4px;
    }
  }

  .search_box {
    margin-top: 34px;
  }

  .section_heading {
    color: #969696;
  }

  .section_heading_button {
    color: #969696;
    margin-top: 10px;
    background-color: white !important;
    border: none;
    text-decoration: underline;
    text-align: center;
  }

  .section_heading_spacing {
    color: #969696;
    padding-bottom: 20px;
  }

  .vl {
    border-left: 2px solid #ebeaea;
    height: 550px;
    display: flex;
    opacity: 0.7;
  }

  .border_radius_primary {
    border-radius: 0.1rem !important;
  }

  .create_collection {
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .row_end {
    padding-left: 92%;
    margin-bottom: 0;
  }

  .close_button {
    width: 10px;
  }

  .card_text_color {
    color: gray;
  }
  .card_shadow {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .card_heading {
    font-size: 11px;
    font-weight: 700;
    text-align: center;
  }
  .name_list {
    display: grid;
  }

  .card_name {
    color: #969696;
  }

  .card_user {
    display: inline-flex;
    padding: 0;
  }

  .card_user_sign {
    font-size: 15px;
    font-weight: 700;
    background-color: white;
    border: white;
  }

  .modal_element {
    width: 60%; /* Occupy the 90% of the screen width */
    max-width: 60%;
    height: 20vh;
    margin-top: 30vh;
  }

  .modal_header {
    border: none !important;
    border: white;
  }

  .sidesection_icon {
    background-color: white;
    border: white;
    color: #969696;
    margin-top: 3px;
    text-align: start;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
  }

  .sidesection_icon_tick {
    padding-top: 5px;
  }
  .section_heading {
    h5 {
      font-size: 12px;
    }
  }
  .sidesection_icon_img {
    width: 12px;
  }
  .sidesection_icon_img_gray {
    width: 12px;
    fill: #969696;
    background-color: white;
    margin-bottom: 1px;
  }
  .cross_img {
    width: 16px;
  }
  .section_heading_bottom {
    color: #969696;
    padding: 0;
  }
  .sidesection_icon:hover,
  .sidesection_icon:focus,
  .sidesection_icon:active {
    background-color: white !important;
    border: white;
    color: #969696 !important;
    margin-top: 3px;
    outline: 0;
    box-shadow: none !important;
  }

  .boards_popover_big {
    padding: 5px;
  }

  .boards_popover_big_edit {
    padding: 10px;
    width: 20%;
  }
  .popover_big {
    width: 100%;
  }

  .boards_popover_small {
    padding: 10px;
    width: 20%;
  }

  .line_spacing {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
    padding: 4px;
  }

  .line_spacing:active,
  .line_spacing:focus {
    line-height: 45%;
    display: grid;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
    color: white;
    img {
      color: white;
    }
  }

  .line_spacing_tick {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0px;
  }

  .line_spacing_tick:active,
  .line_spacing_tick:focus {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    background-color: #219186;
    color: white;
  }

  .line_spacing_name {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    label {
      align-content: center;
      font-size: 11px;
    }
  }

  .list_name {
    align-items: center;
    align-self: center;
    padding-top: 4px;
  }

  .line_spacing_name:active,
  .line_spacing_name:focus {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #219186;
  }

  .line_spacing_pencil {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
  }
  .line_spacing_pencil:active,
  .line_spacing_pencil:focus {
    line-height: 50%;
    display: flex;
    flex-wrap: wrap;
    background-color: #219186;
    color: white;
  }

  .button_nobg {
    background-color: white;
    border: none;
    margin-top: 10px;
  }

  .button_cross {
    width: auto;
    background-color: white;
    border: none;
  }

  .buttons {
    display: flex;
    padding: 0;
  }

  .buttons_div_long {
    width: 70%;
  }

  .buttons_div {
    width: 25%;
  }

  .danger_button {
    background-color: #fe0a13;
    margin: 10px;
  }

  .danger_button:active,
  .danger_button:hover,
  .danger_button:focus {
    background-color: #cf1218;
    margin: 10px;
  }

  .normal_button {
    margin: 10px;
  }

  .normal_button:active,
  .normal_button:hover,
  .normal_button:focus {
    margin: 10px;
  }
}

.collection_edit_list_item {
  &:hover {
    background-color: map-get($theme-colors, 'primary');
    color: #fff;
  }
}

.collection_checked {
  width: 8px;
  margin-right: 1rem;
}

.remove_badge_btn {
  line-height: 0;

  > img {
    width: 0.6rem;
  }
}

.scrollable_collection_tag_box {
  max-height: 300px;
  overflow: auto;
}
