nav.navbar {
  margin-left: 0px;
  background-color: #fff;
  img {
    width: 18px;
  }
  .username {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
  }
  .availibility {
    font-weight: 500;
    font-size: 0.8rem;
    text-align: center;
  }
  .active-user-icon-size {
    width: 2.4rem;
  }
}

.navbar-shadow {
  box-shadow: 0px 3px 5px #c7c7c7;
}

.notification-badge {
  top: -19px;
  left: 8px;
  border-radius: 100%;
  width: 30px;
  padding-top: 3px;
  padding-right: 0.5px;
  height: 30px;
  > p {
    color: white;
    text-align: center;
    vertical-align: middle;
  }
}

@media (min-width: 320px) {
  .collapsed-navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

@media (min-width: 768px) {
  .notification-badge {
    top: -19px;
    left: 17px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    padding-right: 0px;
    padding-top: 0.5px;
    > p {
      color: white;
      text-align: center;
      vertical-align: middle;
    }
  }
}

@media (min-width: 992px) {
  nav.navbar {
    margin-left: 270px;
  }
}
