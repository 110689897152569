.custom_bottom_border {
  border-width: 0.15rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: lightgray;
  border-radius: 0;
}

.card_shadow {
  box-shadow: 0px 2px 5px #2e2e2e;
}
.card_border_radius {
  border-radius: 10px;
}
.custom_white_color {
  color: #e8e8e8;
}

.custom_btn {
  min-height: 3rem;
}

.active_fields:active,
.active_fields:focus {
  box-shadow: none;
  border-width: 0.15rem;
  background: white;
}

.active_fields_no_border:active,
.active_fields_no_border:focus {
  box-shadow: none;
}

.create_ac_text {
  font-size: 0.9rem;
}
.register_kimp_icon {
  width: 5rem;
}
@media (min-width: 768px) {
  .custom_btn {
    min-height: 4rem;
  }

  .create_ac_text {
    font-size: 1.2rem;
  }
  .register_kimp_icon {
    display: none;
  }
}
