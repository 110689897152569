.custom_text_size {
  font-size: 0.8rem;
}
.team_role_item:hover {
  background-color: #219186;
  color: white !important;
}
.team_role_dropdown_toggle {
  top: 0;
  right: 0;
  min-width: 110px;
  border: none;
  color: grey !important;
  background-color: white;
  border-radius: 0rem;
  border-color: grey;
  background: white !important;
  box-shadow: none;
  border-radius: 0.25rem;
  &::after {
    display: none;
  }
}
.team_role_dropdown_toggle:active,
.team_role_dropdown_toggle:hover,
.team_role_dropdown_toggle:focus {
  background-color: white;
  background: white !important;
  color: grey !important;
  border-color: grey;
  box-shadow: none !important;
  border: none;
}
.team_role_menu {
  min-width: 150px !important;
  z-index: 1020 !important;
}
.border_all {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
