.custom_bottom_border {
  border-width: 0.15rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: lightgray;
  border-radius: 0;
}

.card_shadow {
  box-shadow: 0px 2px 5px #2e2e2e;
}
.card_border_radius {
  border-radius: 10px;
}
.custom_white_color {
  color: #e8e8e8;
}

.background_image {
  background: url('../../assets/images/login-background.png');
}

.custom_btn {
  min-height: 3rem;
}

.active_fields:active,
.active_fields:focus {
  box-shadow: none;
  border-width: 0.15rem;
  background: white;
}

.login_text {
  font-size: 0.9rem;
}

.wrap_items {
  flex-direction: column;
}

.login_kimp_icon {
  width: 4rem;
}

@media (min-width: 376px) {
  .wrap_items {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .wrap_items {
    flex-direction: row;
  }
  .custom_btn {
    min-height: 4rem;
  }
  .login_text {
    font-size: 1.2rem;
  }
  .login_kimp_icon {
    display: none;
  }
}
