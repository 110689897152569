aside {
  color: white;
  position: fixed;
  width: 270px;
  height: calc(100vh - 56px);
  top: 56px;
  z-index: 1020;
  left: -270px;
  transition: left 0.3s;
  overflow-y: auto;
  &.is-open {
    left: 0px;
  }

  img {
    width: 18px;
    margin-right: 0.5rem;
  }

  > a {
    padding: 0.75rem 0.75rem;
    display: block;
    color: white;
    text-decoration: none;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      text-decoration: none;
      color: white;
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.1);
      text-decoration: none;
      color: white;
    }
    img {
      margin-right: 1rem;
    }
  }

  button.btn-collapse {
    background-color: transparent;
    border: 0px;
    padding: 0.75rem 0.75rem;
    display: block;
    color: white;
    width: 100%;
    text-align: left;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: white;
    }
    img {
      margin-right: 1rem;
    }
    svg {
      margin-left: 1rem;
      transition: transform 0.3s;
    }
    &.is-open {
      background-color: rgba(255, 255, 255, 0.1);
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  ul {
    list-style-position: inside;
    li {
      border-bottom: 1px solid #bdbdbd;
      padding: 0.5rem 0rem;
      a {
        color: #fff;
        &:hover {
          color: white;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }
}

.side-nav-request-btn:hover,
.side-nav-request-btn:active,
.side-nav-request-btn:focus {
  background-color: #f7d208;
  background: #f7d208 !important;
}

.logo {
  font-size: 1.5rem;
  font-family: 'Cinzel Decorative', cursive;
}
.main-logo-icon-size {
  width: 9rem;
}

aside {
  left: -270px;
}

// .compressed_menu {
// }

@media (min-width: 768px) {
  aside {
    left: 0px;
    height: calc(100vh - 75px);
    top: 75px;
  }
}
@media (min-width: 992px) {
  aside {
    left: 0px;
    height: 100vh;
    top: 0px;
    > a {
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        text-decoration: none;
        color: white;
      }
    }
  }
}
