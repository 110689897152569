.menu_hover {
  position: absolute;
  top: 0;
  right: -400px;
  width: 290px;
  height: 100%;
  transition: right 0.3s;
  z-index: 1010;
  overflow-y: scroll;
  &.show_menu {
    right: 0 !important;
  }
}

.hideMenu {
  right: -400px !important;
}

.bot_icon_size {
  width: 2.5rem;
}

.close_icon_size {
  width: 1rem;
}
.hover_btn {
  top: 0.5rem;
  right: 0.8rem;
  background: white !important;
  box-shadow: none !important;
  border: none !important;
}
.hover_bck_btn {
  top: 0.9rem;
  left: 1rem;
  background: white !important;
  box-shadow: none !important;
  border: none !important;
}

.hover_btn:focus,
.hover_btn:active,
.hover_btn:focus {
  top: 0.5rem;
  right: 0.8rem;
  background: white !important;
  box-shadow: none !important;
  border: none !important;
}

.border_bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.border_bottom:nth-child(8) {
  border-bottom: none !important;
}

.custom_padding_details {
  div {
    padding: 0.5rem 1rem !important;
    text-align: left;
  }
}

.overflow_activity_box {
  overflow-y: scroll;
}

.btn_structure {
  min-width: 150px !important;
}

.achive_btn_size {
  min-width: 78px;
  min-height: 42px;
}

@media (min-width: 320px) {
  .menu_hover {
    width: 290px;
  }
}

@media (min-width: 370px) {
  .menu_hover {
    width: 320px;
  }
}

@media (min-width: 1024px) {
  // .menu_hover {
  // height: 100%;
  // }
}
