@import '../partials/colors';

.icon_size {
  width: 4rem;
}

.team_list_table {
  thead {
    tr {
      box-shadow: 0px 10px 15px #ededed;
      border: 1px solid #e8e7e7;
    }
  }
  tbody {
    tr {
      background-color: #fff;
      border: 1px solid #e8e7e7;
    }
  }
}

.on_focus_btn:focus,
.on_focus_btn:active,
.on_focus_btn:hover {
  text-decoration: underline;
}

.btn_structure {
  min-width: 100px !important;
}
