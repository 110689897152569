.custom_text_size {
  font-size: 0.8rem;
}
.progress_item:hover {
  background-color: #219186;
  color: white !important;
}
.progress_dropdown_toggle {
  top: 0;
  right: 0;
  min-width: 110px;
  border: 2px solid #969696 !important;
  color: grey !important;
  background-color: white;
  border-radius: 0rem;
  border-color: grey;
  background: white !important;
  box-shadow: none;
  border-radius: 0.25rem;
  &::after {
    display: none;
  }
}
.progress_dropdown_toggle:active,
.progress_dropdown_toggle:hover,
.progress_dropdown_toggle:focus {
  background-color: white;
  background: white !important;
  color: grey !important;
  border-color: grey;
  box-shadow: none !important;
}
.progress_menu {
  min-width: 150px !important;
}
