@import '../partials/colors';

.subscription_table {
  margin: 0px -0.5rem;
  > div {
    padding: 0px 0.5rem;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 1em;
    thead {
      th {
        color: #fff;
        background-color: map-get($theme-colors, 'primary');
        border-bottom: none;
        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    tbody {
      tr {
        background-color: #fff;
        box-shadow: 0px 10px 15px #ededed;
        border: 1px solid #e8e7e7;
      }
      td {
        margin-top: 1.5rem;
        vertical-align: middle;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
}

.change_payment_card {
  background-color: #f0f0f0;
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .change_payment_card {
    padding-right: 1rem;
  }
}

@media (min-width: 1200px) {
  .change_payment_card {
    padding-right: 1rem;
    width: 100%;
    max-width: 850px;
    background-color: #f0f0f0;
    button {
      white-space: nowrap;
    }
  }
}
