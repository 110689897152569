@import '../partials/colors';

.support_tabs {
  overflow: scroll;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  border-bottom: 2px solid #f0f0f0;
  button {
    border: none;
    background-color: white;
    display: inline-flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 140px;
    text-align: center;
    justify-content: center;
    align-items: center;
    &.active {
      border-bottom: 4px solid map-get($theme-colors, 'primary');
      color: map-get($theme-colors, 'darker');
      font-weight: 600;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 140px;
      background-color: white;
    }
  }
}

.member_icon_size {
  width: 2.4rem;
}
.border_bottom {
  border-bottom: 1px solid lightgray;
}

.border_btm {
  border-bottom: 1px solid lightgray;
}

.border_btm:last-child {
  border-bottom: none;
}

.client_table {
  margin: 0px -0.5rem;
  > div {
    padding: 0px 0.5rem;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 1rem;
    thead {
      th {
        color: #fff;
        background-color: map-get($theme-colors, 'primary');
        border-bottom: none;
      }
    }
    tbody {
      tr {
        background-color: #fff;
        border: 1px solid #e8e7e7;
        box-shadow: 0px 10px 15px #ededed;
      }
      td {
        margin-top: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        vertical-align: middle;
        border-bottom: 1px solid #dee2e6;
        &:first-child {
          border-left: 1px solid #dee2e6;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
        &:last-child {
          border-right: 1px solid #dee2e6;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
  }
}

.member_table {
  > div {
    padding: 0px 0.5rem;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 1rem;
    tbody {
      tr {
        background-color: #fff;
        border: 1px solid #e8e7e7;
      }
      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        vertical-align: middle;
        border-top: 0;
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

.border_card {
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-clip: border-box;
}

.border_all {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.border_top {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.border_bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.border_right {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
.border_left {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.subscriptions_table,
.subscriptions_product_table,
.subscriptions_renewal_table,
.subscriptions_status_table,
.associated_order_table {
  table {
    thead {
      th {
        border: none;
      }
    }
    tbody {
      td {
        border: none;
      }
    }
  }
}

.associated_order_table {
  table {
    thead {
      th {
        border: none;
      }
    }
    tbody {
      td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
      }
    }
  }
}

.nichole_icon_size {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.nichole_icon_size_sm {
  width: 2.5rem;
}

.border_all {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.no_border_radius {
  border-radius: none !important;
}
.custom_search_icon {
  width: 1rem;
}

.no_right_border {
  border-right: none;
}

.custom_search_bar {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid #ced4da;
  border-radius: none;
}

.custom_search_bar:focus {
  outline: white;
}

.custom_search_icon_bar {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid #ced4da;
}

.custom_right_border {
  margin-right: 1px;
}

.input_group_border_color {
  border: 1px solid #ced4da;
  padding-top: 2px;
  padding-bottom: 2px;
}
.input_group_border_color:focus,
.input_group_border_color:active {
  border: 1px solid #ced4da !important;
}

.no_left_border {
  border-left: none;
}

.role_btn_size {
  min-width: 150px !important;
}

.add_icon {
  margin-left: 0.5rem;
}

.btn_structure {
  min-width: 140px !important;
}

.switch_lists {
  button {
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: white;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #323232;
    border-radius: 0.1rem;
    min-width: 140px !important;
    color: #323232;
    &.active {
      background-color: map-get($theme-colors, 'primary');
      border: 1px solid map-get($theme-colors, 'primary');
      color: #ffffff;
    }
  }
}

.invite_btn,
.invite_btn:hover,
.invite_btn:active,
.invite_btn:focus {
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 140px !important;
  background-color: map-get($theme-colors, 'primary');
  border: 1px solid map-get($theme-colors, 'primary');
  color: #ffffff;
}

.subscribed_btn_color {
  color: #2f54d1;
}
.cancelled_btn_color {
  color: #ff0a0a;
}
.onhold_btn_color {
  color: #b216f7;
}

.search_feed_icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.5' height='11.5' viewBox='0 0 11.5 11.5'%3E%3Cg id='search' transform='translate(0 -0.003)'%3E%3Cpath id='Path_3155' data-name='Path 3155' d='M11.245,10,8.874,7.626a4.733,4.733,0,0,0,.857-2.758,4.779,4.779,0,0,0-.384-1.89A4.765,4.765,0,0,0,6.756.387a4.849,4.849,0,0,0-3.78,0A4.765,4.765,0,0,0,.384,2.978a4.849,4.849,0,0,0,0,3.78A4.766,4.766,0,0,0,2.975,9.35a4.781,4.781,0,0,0,1.89.384,4.733,4.733,0,0,0,2.758-.857L9.994,11.24a.821.821,0,0,0,.622.263A.885.885,0,0,0,11.245,10ZM7.053,7.056a2.982,2.982,0,0,1-2.187.909,2.982,2.982,0,0,1-2.187-.909,2.981,2.981,0,0,1-.909-2.187,2.982,2.982,0,0,1,.909-2.187,2.981,2.981,0,0,1,2.187-.909,2.982,2.982,0,0,1,2.187.909,2.981,2.981,0,0,1,.909,2.187A2.982,2.982,0,0,1,7.053,7.056Z' transform='translate(0 0)' fill='%23323232'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  // padding: 0.1rem;
}

.client_header_btn {
  min-width: 120px;
}

.header_color {
  background-color: #f0f0f0;
}

.member_support_bottom:nth-child(9) {
  border-bottom: none !important;
}

.text_width {
  min-width: 180px;
  // height: 100%;
}

.text_width::placeholder {
  color: map-get($theme-colors, 'secondary');
}

@media (min-width: 320px) {
  .switch_lists {
    button {
      min-width: 120px;
    }
  }
}

@media (min-width: 376px) {
  .support_tabs {
    overflow: hidden;
  }
}

@media (min-width: 425px) {
  .support_tabs {
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .support_tabs {
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .text_width {
    // min-width: 180px;
    height: 33px;
  }
}
