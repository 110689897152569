@import '../partials/colors';

.custom_text_size {
  font-size: 0.8rem;
}

.custom_no_show_icon::after {
  content: none !important;
}
.arrow_icon_size {
  width: 1rem;
}
.main_card_height {
  min-height: 50vh;
}

.card_height_60vh {
  min-height: 60vh;
}

.hover_option {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.hover_row_option {
  position: absolute;
  right: 0rem;
  top: 0rem;
}

.photo_gallery_icon_size {
  width: 4.5rem;
  margin-bottom: 10px;
}

.gallery_card {
  min-width: 170px;
  min-height: 120px;
}

.gallery_card_no_name {
  min-width: 170px;
  min-height: 150px;
}

.hover_icon_option {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.image_type_icon_size {
  width: 1.5rem;
}
.plus_icon_size {
  width: 5rem;
}

.card_dropdown_toggle {
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;
}

.card_dropdown_toggle:active,
.card_dropdown_toggle:hover,
.card_dropdown_toggle:focus {
  background-color: white;
  background: white !important;
  color: grey !important;
  border-color: grey;
  box-shadow: none !important;
}

.selected_item:hover {
  background-color: #219186;
  color: white !important;
}

.selected_text:hover {
  color: white !important;
}

.delete_icon_color {
  fill: #969696;
}

.delete_icon_color:hover {
  fill: white !important;
}

.unsorted_dropdown_toggle {
  top: 0;
  right: 0;
  min-width: 110px;
  color: grey !important;
  background-color: white;
  border-radius: 0rem;
  border-color: grey;
  background: white !important;
  box-shadow: none;
  border-radius: 0.25rem;
  &::after {
    display: none;
  }
}
.unsorted_dropdown_toggle:active,
.unsorted_dropdown_toggle:hover,
.unsorted_dropdown_toggle:focus {
  background-color: white;
  background: white !important;
  color: grey !important;
  border-color: grey;
  box-shadow: none !important;
}

.unsorted_item:hover {
  background-color: #219186;
  color: white !important;
}

.tick_icon_size {
  width: 0.6rem;
}
.unsorted_menu {
  min-width: 120px !important;
}
.asset_box_design {
  height: 100%;
  width: 115%;
}

.delete_icon_color {
  fill: #969696;
}

.delete_icon_color:hover {
  fill: white !important;
}

.no_box_shadow_btn:focus {
  box-shadow: none;
}

@media (min-width: 320px) {
  .unsorted_btn {
    padding: 0 0;
  }
  .asset_box_design {
    height: 250px;
    width: 115%;
  }
}

@media (min-width: 375px) {
  .unsorted_btn {
    padding: 0 15px;
  }
  .asset_box_design {
    height: 273px;
    width: 100%;
  }
}

@media (min-width: 425px) {
  .asset_box_design {
    height: 323px;
    width: 100%;
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .asset_box_design {
    height: 396px;
    width: 100%;
  }
  .unsorted_menu {
    // width: 180px;
    min-width: 140px !important;
  }
  .unsorted_dropdown_toggle {
    top: 0;
    right: 0;
    min-width: 140px;
    color: grey !important;
    background-color: white;
    border-radius: 0rem;
    border-color: grey;
    background: white !important;
    box-shadow: none;
    border-radius: 0.25rem;
    &::after {
      display: none;
    }
  }
}

.border_all {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

@media (min-width: 992px) {
  .asset_box_design {
    height: 440px;
    width: 73%;
  }
}

@media (min-width: 1024px) {
  .asset_box_design {
    height: 384px;
    width: 384px;
  }
}

@media (min-width: 1200px) {
  .asset_box_design {
    height: 411px;
    width: 411px;
  }
}

.asset_name {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.btn_add_brand {
  background-color: #fff;
  transition: all 0.5s;
  &:hover {
    background-color: rgba(map-get($theme-colors, 'primary'), 0.2);
  }
}

.img_file_type {
  position: absolute;
  top: 6px;
  right: 6px;
}

.asset_image_item {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
}
