.background_fill_primary {
  background-color: #389286 !important;
}

.background_fill_secondary {
  background-color: #f0f0f0;
}
.background_fill_warning {
  background-color: #f6d337;
}
.background_fill_custom_green {
  background-color: #e2fffc;
}

.text_color_primary {
  color: #389286;
}

.text_color_white {
  color: white;
}

.image_container {
  background-color: wheat;
}

.background_fill_image {
  background-image: url('../../assets/images/banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.border_radius_primary {
  border-radius: 0.6rem !important;
}

.no_border {
  border: none;
}

.user_icon_size {
  width: 1.5rem;
}

.click_arrow_icon_size {
  width: 0.5rem;
}

.card_text_color {
  color: gray;
}
.card_shadow {
  box-shadow: 0.01rem 0.01rem 0.1rem #aaaaaa;
}

.underline_text {
  text-decoration: underline;
}

.text_color_style {
  color: white;
  opacity: 0.7;
}

.kimp_banner_size {
  width: 15rem;
}

.title_text_size_custom {
  font-size: 1.5rem;
  font-family: 'Cinzel Decorative', cursive;
}

.sidebar_text_size_custom {
  font-size: 1.2rem;
}
.dashboard_icon_size {
  width: 1rem;
}

.dashboard_width_full {
  width: 100%;
}

.active_dashboard_button:hover {
  background-color: #3d9d91;
}

.button_active {
  background-color: #f6d337 !important;
  border: 1px solid #f6d337 !important;
  background: #f6d337 !important;
}
.button_active:active,
.button_active:hover,
.button_active:focus {
  background-color: #f6d337 !important;
}

.dashcard_size {
  min-height: 200px;
}

.dashcard_btn_click:active,
.dashcard_btn_click:focus,
.dashcard_btn_click:hover {
  box-shadow: none;
}

@media (min-width: 768px) {
  .dashcard_size {
    min-height: 220px;
  }
}

@media (min-width: 992px) {
  .dashcard_details_size {
    min-height: 100px;
  }
  .title_text_custom {
    font-size: 0.8rem;
  }
  .dashcard_size {
    min-height: 255px;
  }
}

@media (min-width: 1200px) {
  .kimp_banner_size {
    width: 20rem;
  }
  .title_text_custom {
    font-size: 1rem;
  }
}
